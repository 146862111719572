.hero-wrapper {
    color: #fff;
    position: relative;
    padding-bottom: 2rem;
    /* background: var(--black); */
}

.hero-container {
    justify-content: space-around;
    align-items: flex-end;
}

/* left side */
.hero-left {
    gap: 3rem;
}

.hero-title {
    position: relative;
    z-index: 1;
}

.hero-title>h1 {
    font-weight: 600;
    font-size: 4rem !important;
    line-height: 4rem;
}

.orange-circle {
    width: 4rem;
    height: 4rem;
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right: 28%;
    top: -10%;
    z-index: -1;
}

.search-bar {
    background-color: #fff;
    border-radius: 5px;
    border: 3px solid rgb(120, 120, 120, 0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
}

.search-bar>input {
    border: none;
    outline: none;
}

.stats {
    width: 100%;
    justify-content: space-between;
}

.stat>:nth-child(1) {
    font-size: 2rem;
}

.stat>:nth-child(1)>:last-child {
    color: orange;
}

/* right side */
.image-container {
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgb(255, 255, 255, 0.12);
}

.image-container>img {
    width: 100%;
    height: 100%;
}

@media (max-width:768px) {
    .hero-container {
        margin-top: 2rem;
    }

    .hero-title>h1 {
        font-size: 2.5rem !important;
        line-height: 3rem;
    }

    .image-container {
        width: 95%;
        height: 25rem;
    }

    .stats {
        justify-content: center;
        gap: 1.5rem;
    }

    .hero-right {
        width: 100%;
    }

    .stats>:nth-child(1) {
        font-size: 1.5rem;
    }
    .stats>:nth-child(2) {
        font-size: 0.8rem;
    }
}