.inner-container{
    gap: 1.5rem;
    background-color: #4161df;
    padding: 2rem;
    border-radius: 10px;
    border: 6px solid #5d77d6;
    text-align: center;
}

.inner-container .primaryText{
    color: white;
    font-weight: 600;
}

.inner-container .secondaryText{
    color: rgb(211, 211, 211);
}

.inner-container .button{
    background-color: #5d77d6;
    border: 1px solid white;
    box-shadow: var(--shadow);
    border-radius: 10px;
}